import { Box } from '@chakra-ui/react'
import React from 'react'

const Enterprize = () => {
  return (
    <Box maxW={"1300"} py={10} m="auto" pt={"140px"}>
      Enterprize
    </Box>
  )
}

export default Enterprize
