import { Box } from '@chakra-ui/react'
import React from 'react'

const Resources = () => {
  return (
    <Box maxW={"1300px"} py={10} m="auto">
      Resources
    </Box>
  )
}

export default Resources
