import { Box } from "@chakra-ui/react";
import React from "react";

const Solutions = () => {
  return (
    <Box maxW={"1300px"} py={10} m="auto">
      Solutions
    </Box>
  );
};

export default Solutions;
