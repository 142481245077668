import "./App.css"
import AllRoutes from "./Routes/AllRoutes";

function App() {
  return (
    <div>
      <AllRoutes />
    </div>
  );
}

export default App;
